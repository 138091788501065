// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10569(02500b1b89)-C18/09/2024-14:35:50-B18/09/2024-14:40:44' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10569(02500b1b89)-C18/09/2024-14:35:50-B18/09/2024-14:40:44",
  branch: "master",
  latestTag: "6.1",
  revCount: "10569",
  shortHash: "02500b1b89",
  longHash: "02500b1b8979f0e0e18b6611c6a30a52d104a53f",
  dateCommit: "18/09/2024-14:35:50",
  dateBuild: "18/09/2024-14:40:44",
  buildType: "Ansible",
  } ;
